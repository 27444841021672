/* Custom fonts */

@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Space+Grotesk:wght@300..700&display=swap');
:root {
  --primary-color: #24a100;
}


/* default css */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #0E1217 !important;
  color: #fff !important;
  font-family: "Space Grotesk", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

.container-lg{
  padding-left: 20px !important;
  padding-right: 20px !important;
}

/* navbar style*/

.logo{
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  font-size: 56px;
  color: #fff !important;
}

nav.navbar {
  padding: 10px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32s ease-in-out;
}

nav.navbar.scrolled {
  background-color: #1D1F26;
  box-shadow: 0 2px 4px #0003;
}

nav.navbar a.navbar-brand {
  /* width: 9%; */
  /* filter: invert(36%) sepia(99%) saturate(1274%) hue-rotate(75deg)
    brightness(98%) contrast(90%); */
}

nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
  margin-top: 15px;
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
  color: #AE1FE3 !important;
}

span.navbar-text {
  display: flex;
  align-items: center;
}

.social-icon {
  display: inline-block;
  margin-left: 14px;
}

.social-icon a {
  width: 42px;
  height: 42px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  justify-content: center;
  align-items: center;
  line-height: 1;
  /* border: 1px solid rgba(255, 255, 355, 0.5);  */
}

.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}

.social-icon a:hover::before {
  transform: scale(1);
}

.social-icon a img {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}

.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(90%)
    hue-rotate(345deg) brightness(100);
}

.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}

.navbar-text button span {
  z-index: 1;
}

.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.navbar-text button:hover {
  color: #121212;
}

.navbar-text button:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}

nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}

nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid #fff;
}

nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #fff;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}

nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: #fff;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translate(8px) rotate(45deg);
  background-color: #fff;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon{
  border-color: transparent;
}

/* Banner */

.banner {
  margin-top: 0;
  padding: 130px 0 180px 0;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}

.banner h1 {
  font-size: 45px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}

.banner p {
  color: #b8b8b8;
  font-size: 15px;
  letter-spacing: 0.8px;
  line-height: 1.5rem;
  width: 96%;
}

.banner button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
}
.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner button:hover svg {
  margin-left: 25px;
}
.banner .banner-image{
  height: 400px;
  margin-top: 50px;
}

.banner .banner-img-wrapper{
  text-align: center;
 }
/* .banner img {
  animation: updown 3s linear infinite;
} */

@keyframes updown {
  0% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(-20px);
  }
}
.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}

.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}


/* Skills */

.skill{
  padding: 0px 0 50px 0;
  position: relative;
  background-color: #282A36;
}

.skill-bx{
 background: #1D1F26; 
  border-radius: 64px;
  text-align: center;
  padding: 60px 20px;
  margin-top: -130px;
} 

.skill h2{
  font-size: 38px;
  font-weight: 700;
}

.skill p{
  color: #b8b8b8;
  font-size: 14px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px 0 75px 0;
}

.skill .skill-slider .react-multiple-carousel__arrow--left  {
  left: 0;
}
.skill .skill-slider .react-multiple-carousel__arrow--right  {
  right: 0;
}

.skill-silder{
  width: 80%;
  margin: 0 auto;
  position: relative;
  background-color: "red";

}

.skill-silder .item img{
  width: 50%;
  padding: 0 auto 15px auto;
}

.background-img-left{
  top: 28%;
  position: absolute;
  bottom: 0;
  width: 40%;
  z-index: -4;
}

/* Project */

.project {
  padding: 5px 0;
  position: relative;
  background-color: #282A36;
}
.project h2 {
	font-size: 38px;
	font-weight: 700;
  text-align: center;
}
.project p {
  color: #B8B8B8;
  font-size: 14px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
}
.project .nav.nav-pills {
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
  margin-bottom: 40px;
}
.project .nav.nav-pills .nav-item {
  width: 33.33333%;
}
.project .nav.nav-pills .nav-link {
  background-color: transparent;
  padding: 17px 0;
  color: #fff;
  width: 100%;
  font-size: 14px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}
.project .nav.nav-pills .nav-link::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background: #1D1F26;
    top: 0;
    left: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
}
.project .nav.nav-pills .nav-link.active::before {
    width: 100% !important;
}

.proj-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 30px;
  /* margin-right: 30px; */
  background-color: #1D1F26;
}

.proj-imgbx .project-box{
  padding: 10px;
}

.proj-imgbx img{
  width: 100%;
  object-fit: cover;
  border-radius: 30px;
  transform: scale(1);
  transition: all .35s ease;
}

.proj-imgbx .proj-img-overlay{
  border-radius: 30px;
  position: relative;
  overflow: hidden;
}


.proj-imgbx .proj-img-overlay .more-info{
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  inset: 0;
  opacity: 0;
  transition:all 0.3s ease-in;
} 

.proj-imgbx .project-box:hover img, .proj-imgbx .project-box:hover .more-info{
  transform: scale(1.1);
  opacity: 1;
  cursor: pointer;
}

.proj-imgbx .proj-txtx{
  color: #121212;
  padding-left: 10px;
  margin: 15px 0 5px 0;
}

.proj-txtx h4 {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.1em;
  margin-bottom: 0;
  padding-bottom: 0;
  color: #fff;
}

.proj-txtx span {
  font-size: 14px;
  color: #d5d7d8;
}


.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}



/* project modal */

.modal .modal-header button.btn-close{
  background-color: "red" !important;
}

.modal .modal-img{
  width: 100%;
  object-fit: cover;
}

.project-modal{
  color: #121212;
}

.project-modal .about-project ul{
  padding-left: 0px;
}

.project-modal .about-project ul li{
  margin-bottom: 10px;
}

.project-modal .about-project ul li .label{
  font-weight: 700;
}


/* react multi carousel */

.modal .react-multi-carousel-list .react-multi-carousel-track{
  margin-bottom: 40px;
}

.modal .react-multi-carousel-list .react-multi-carousel-dot button{
  width: 16px;
  height: 16px;
  border-width: 0.1px;
}

/* resume modal */
.modal{
  z-index: 9999 !important;
}

.modal .modal-body .react-pdf__Page__canvas{
  width: 100% !important;
  height:auto !important;
}

.pdf-modal .modal-content{
  height: 600px;
  overflow: scroll;
}

.modal .modal-header .resume-dwnld-btn{
  border: 1px solid #0E1217;
  padding: 10px 18px;
  color: #0E1217;
  position: relative;
  background-color: transparent;

}

.modal .modal-header .resume-dwnld-btn{
  z-index: 1;
  font-size: 20px;
  font-weight: bold;
}
.modal .modal-header .resume-dwnld-btn .dwnld-icon{
  margin-left: 10px;
  color:#0E1217;
 
}

.modal .modal-header .resume-dwnld-btn::before{
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  top:0;
  left:0;
  background-color: #0E1217;
  z-index: -1;
  transition: 0.32s ease;
}


.modal .modal-header button:hover,.modal .modal-header button:hover .dwnld-icon{
  color: #fff !important;

}

.modal .modal-header .resume-dwnld-btn:hover::before{
  width: 100%;

}


/* experience */

.experience {
  padding: 5px 0;
  position: relative;
  background-color: #282A36;
}

.experience h2 {
	font-size: 38px;
	font-weight: 700;
  text-align: center;
}
.experience p {
  color: #B8B8B8;
  font-size: 14px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
}

.experience .timeline-card .vertical-timeline-element-content{
  background-color: #1D1F25;
  box-shadow: 0 3px 0 #3E404B;
  transition: 0.32s ease-in-out;
}

.experience .timeline-card .vertical-timeline-element-content:hover{
  scale: 1.05;
}

.experience .timeline-card .vertical-timeline-element-content .job-desc {
  padding-left: 10px;
}


.experience .timeline-card .vertical-timeline-element-content h3{
  color: #AE1FE3;
}

.experience .timeline-card .vertical-timeline-element-content h4{
  color: #AE1FE3;
}



.experience .timeline-card .vertical-timeline-element-content .job-desc li{
  color: #B8B8B8;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px 0px 30px 0px;
  padding: 0;
}




/* Footer */

.footer{
  padding: 50px 0 30px 0;
  background-color: #1D1F26;
}


/* media query */


@media only screen and (max-width: 420px) {

  /* banner */
  .banner .banner-image{
    height: 300px;
  }

.navbar-text {
      padding-top: 1rem !important;
  }

  .navbar-text button{
    padding: 15px 24px;
  }

  nav .social-icon a {
    margin-right: 20px ;
  }
}

@media only screen and (min-width: 420px) {
  .navbar-text {
    padding-top: 1rem !important;
}

nav .social-icon a {
  margin-right: 20px ;
}
}

/* Small devices such as large phones (640px and up) */
@media only screen and (min-width: 640px) {

  /* banner */
  .banner .banner-image{
    height: 400px;
  }

  .banner .banner-img-wrapper{
   text-align: center;
  }
  

   
}

/* Medium devices such as tablets (768px and up) */
@media only screen and (min-width: 768px) {
/* banner */
  .banner{
    padding: 200px 0 180px 0;
    }
    
  .banner h1{
  font-size: 45px;
  }

  .banner .banner-img-wrapper{
    text-align: right;

  }
  .banner .banner-image{
    height: 380px;
    margin-top: 0px;
  }

  /* project */

  .project, .experience{
    padding: 80px 0;

  }

  .project .nav.nav-pills .nav-link{
    font-size: 17px;
  }

  .project p, .experience p{
    font-size: 18px;
  }
  
  .project h2, .experience h2{
    font-size: 45px;
  }

  /* skill */
  .skill p{
    font-size: 18px;
  }

  .skill h2{
    font-size: 45px;
  }

   /* modal with pdf */
   .pdf-modal .modal-content{
    height: 600px;
    overflow: scroll;
  }

}

/* Large devices such as laptops (992px and up) */
@media only screen and (min-width: 992px) {

  /* banner */
  .banner{
  padding: 260px 0 180px 0;
  }

  .banner h1{
    font-size: 65px;
    }
    .banner p{
      font-size: 18px;
    }
    .banner .banner-image{
      height: 500px;
    }

    /* navbar */
    nav.navbar {
      background-color: transparent;
      box-shadow: none;
    }

    nav.navbar.scrolled {
      padding: 0px 0;
    }

    .navbar-text {
      padding-top: 0 !important;
  }

    nav.navbar .navbar-nav .nav-link.navbar-link{
      margin-top: 0px;
    }

    /* modal with pdf */
    .pdf-modal .modal-content{
      height: 700px;
      overflow: scroll;
    }

 


}

@media only screen and (min-width: 992px) and (max-width: 1200px){
  .project-modal .modal-dialog{
    --bs-modal-width: 900px !important;
  }

}


